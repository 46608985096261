import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainPageComponent } from '../layout/main-page/main-page.component';
import { DocumentPrinterListComponent } from './components/document-printer-list/document-printer-list.component';


const routes: Routes = [
  {
    path: '',
    component: MainPageComponent,
    children: [
      { path: 'printerList', component: DocumentPrinterListComponent }
    ]
  }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentRoutingModule { }