import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElectronDetectionService } from './services/electron-detection.service';
import { ElectronService } from './services/electron.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class ElectronModule {
  static forRoot(): ModuleWithProviders<ElectronModule> {
    return {
      ngModule: ElectronModule,
      providers: [
        ElectronDetectionService,
        ElectronService
      ]
    };
  }
 }
