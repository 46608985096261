import { Injectable } from "@angular/core";
import { ElectronService } from "src/app/electron/services/electron.service";
import { PortalConfig } from "../model/config";
import { Observable, throwError } from "rxjs";
import { InternalError } from "src/app/common-app/model/internal-error";

@Injectable()
export class ConfigElectronService {
    constructor(private electronService: ElectronService) { }

    getConfigPortal(): Observable<PortalConfig> {
        if (this.electronService.isElectronApp) {
          return this.electronService.invoke<PortalConfig>('get-config');
        } else {
          return throwError(() =>
            new InternalError('CONFIG_0004', 'No se puede obtener la configuracion', undefined, undefined));
        }
      }

      saveConfigPortal(data: PortalConfig): Observable<PortalConfig> {
        if (this.electronService.isElectronApp) {
          return this.electronService.invoke<PortalConfig>('save-config', data);
        } else {
          return throwError(() =>
            new InternalError('CONFIG_0004', 'No se puede guardar la configuracion', undefined, undefined));
        }
      }
}