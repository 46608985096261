import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, filter, map, mergeMap, of } from 'rxjs';
import { AuthorizationTokenService } from 'src/app/security/services/authorization-token.service';
import { BridgeTokenResponse } from '../model/bridge-token-response';
import { HttpHeaders } from '@angular/common/http';
import { BridgeTokenConfigProviderService } from './bridge-token-config-provider.service';

@Injectable()
export class BridgeTokenService implements AuthorizationTokenService {

  private token$ = new BehaviorSubject<string | undefined>(undefined);
  private externalToken$ = new BehaviorSubject<string | undefined>(undefined);

  constructor(
    private httpClient: HttpClient,
    private bridgeTokenConfigProviderService: BridgeTokenConfigProviderService
  ) {
    this.externalToken$.pipe(
      filter(i => i !== undefined),
      mergeMap(data => {
      const site = window.location.hostname;
      const url = this.bridgeTokenConfigProviderService.getUrl();
      let params = new HttpParams().set('site', site);
      const code = this.bridgeTokenConfigProviderService.getCode();
      if (code !== undefined) {
        params = params.set('code', code);
      }

      return this.httpClient.get<BridgeTokenResponse>(url, {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${data}`
        }),
        params: params
      }).pipe(map(i => i.access_token));
    }
    )).subscribe({
      next: i => this.token$.next(i),
      error: e => console.error("Error getting token", e)
    });
  }
  
  public getExternalToken(): Observable<string> {
    return this.externalToken$.asObservable().pipe(filter(i => i !== undefined), map(i => i as string));
  }

  public externalToken(token: string): void {
    this.externalToken$.next(token);
  }

  public getToken(): Observable<string> {
    return this.token$.asObservable().pipe(filter(i => i !== undefined), map(i => i as string));
  }
  public isAutenticated(): Observable<boolean> {
    return this.token$.pipe(map(i => i !== undefined));
  }
}
