import { Component, OnInit } from '@angular/core';
import { DocumentPrintService } from '../../services/document-print.service';

@Component({
  selector: 'app-document-printer-list',
  templateUrl: './document-printer-list.component.html',
  styleUrls: ['./document-printer-list.component.scss']
})
export class DocumentPrinterListComponent implements OnInit {

  protected printerList$ = this.documentPrintService.getPrinterList();
  protected displayedColumns: string[] = ['printerName', 'documentType'];
  

  constructor(protected documentPrintService: DocumentPrintService) { 
     
  }

  ngOnInit(): void {
  }

}
