export class Printer {
    documentType: string;
    printerName: string;
    scale: string;
    paperSize: string;

    constructor(documentType: string, printerName: string, scale: string, paperSize: string) {
        this.documentType = documentType;
        this.printerName = printerName;
        this.scale = scale;
        this.paperSize = paperSize;
    }
}

export class PickingOnline {
    url: string;
    packingStation: string;
    company: string;
    environment: string;
    requestWeight: boolean;
    printers: Printer[];

    constructor(url: string, packingStation: string, company: string, environment: string, requestWeight: boolean, printers: Printer[]) {
        this.url = url;
        this.packingStation = packingStation;
        this.company = company;
        this.environment = environment;
        this.requestWeight = requestWeight;
        this.printers = printers;
    }
}

export class AppConfig {
    pickingOnline: PickingOnline;

    constructor(pickingOnline: PickingOnline) {
        this.pickingOnline = pickingOnline;
    }
}

export class PortalConfig {
    app: AppConfig;

    constructor(app: AppConfig) {
        this.app = app;
    }
}