import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentListComponent } from './components/document-list/document-list.component';
import { DocumentPrintDialogComponent } from './components/document-print-dialog/document-print-dialog.component';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox'; 
import { MatIconModule } from '@angular/material/icon';
import { DocumentPrintBrowserService } from './services/document-print-browser.service';
import { DocumentPrintService } from './services/document-print.service';
import { electronFactoryService } from '../electron/services/electron-factory';
import { ElectronDetectionService } from '../electron/services/electron-detection.service';
import { DocumentPrintElectronService } from './services/document-print-electron.service';
import { DocumentPrinterListComponent } from './components/document-printer-list/document-printer-list.component';
import { DocumentRoutingModule } from './document-routing.module';



@NgModule({
  declarations: [
    DocumentListComponent,
    DocumentPrintDialogComponent,
    DocumentPrinterListComponent
  ],
  imports: [
    CommonModule,
    DocumentRoutingModule,
    MatTableModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule
  ],
  exports: [
    DocumentPrintDialogComponent
  ]
})
export class DocumentModule { 
  static forRoot(): ModuleWithProviders<DocumentModule> {
    return {
      ngModule: DocumentModule,
      providers: [
        DocumentPrintBrowserService,
        DocumentPrintElectronService,
        { provide: DocumentPrintService, 
          useFactory: electronFactoryService,
          deps: [ElectronDetectionService, DocumentPrintBrowserService, DocumentPrintElectronService]}
      ]
    };
  }
}
