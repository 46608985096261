import { Injectable } from "@angular/core";
import { catchError, map, Observable, Subject, throwError } from "rxjs";
import { InternalError } from "src/app/common-app/model/internal-error";
import { PrintStatus } from "../model/print-status";
import { PrintService } from './print.service';
import { ElectronService } from "src/app/electron/services/electron.service";


@Injectable()
export class PrintElectronService extends PrintService {

  constructor(private electronService: ElectronService) {
    super();
  }

  printFile(data: Uint8Array, printerName: string): Observable<PrintStatus> {
    const event$ = new Subject<PrintStatus>();
    if (this.electronService.isElectronApp) {
      return this.electronService.invoke('print-pdf-content', data, printerName)
        .pipe(
          map((result) => PrintStatus.printDialogClose),
          catchError((error) =>
            throwError(() =>
              new InternalError('PRINTPDF_0002', 'Fallo al imprimir el fichero', error, undefined)
            ))
        );

    } else {
      return throwError(() =>
        new InternalError('PRINTPDF_0004', 'No se puede imprimir en este entorno', undefined, undefined));
    }
  }
}
