import { Injectable } from '@angular/core';
import { ElectronWindow } from '../model/electron-window';

@Injectable()
export class ElectronDetectionService {

  constructor() { }

  isElectron(): boolean {
    const electronWindow = window as any as ElectronWindow;
    if (electronWindow && electronWindow.electronAPI) {
         return true;
    }

    return false;
  }
}
