<p>Printer list</p>
<p *ngFor="let printer of printerList$ | async">
   {{printer.printerName}} [{{printer.documentType}}]
</p>

<mat-table [dataSource]="(printerList$ | async) || []">
  <ng-container matColumnDef="printerName">
    <mat-header-cell *matHeaderCellDef> Printer Name </mat-header-cell>
    <mat-cell *matCellDef="let printer"> {{printer.printerName}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="documentType">
    <mat-header-cell *matHeaderCellDef> Document Type </mat-header-cell>
    <mat-cell *matCellDef="let printer"> {{printer.documentType}} </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
