import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { DocumentContentStatus } from '../../model/document-content-status';
import { TextNotificationService } from 'src/app/common-app/services/text-notification.service';
import { DocumentPrintService } from '../../services/document-print.service';
import { InternalError } from 'src/app/common-app/model/internal-error';
import { ErrorHistoryService } from 'src/app/common-app/services/error-history.service';
import { LogisticDocument } from '../../model/logistic-document';
import { Subject, takeUntil, firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() documentList: LogisticDocument[] = [];
  @Output() documentSelected: EventEmitter<LogisticDocument[]> = new EventEmitter<LogisticDocument[]>()

  protected dataSource: LogisticDocument[] = [];
  protected displayedColumns = ['select', 'description', 'type', 'print', 'view'];
  protected selection = new SelectionModel<LogisticDocument>(true, []);
  protected DocumentPrintStatus = DocumentContentStatus;
  protected destroy$ = new Subject<void>();

  constructor(
    private textNotificationService: TextNotificationService,
    private documentPrintService: DocumentPrintService,
    private errorHistoryService: ErrorHistoryService,
  ) { 
    this.selection.changed.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.documentSelected.emit(this.selection.selected);
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const documentListChange = changes['documentList'];
    if (documentListChange !== undefined && documentListChange.currentValue !== undefined) {
      this.dataSource = this.documentList.sort((a, b) => a.type.localeCompare(b.type));
    } else {
      this.dataSource = [];
    }
    this.selection.clear(false);
    this.selectAll();
  }

  ngOnInit(): void {
  }

  private selectAll(): void {
    this.selection.select(...this.dataSource.filter(i => i.error === undefined 
      && i.documentStatus === DocumentContentStatus.Ok && (i.pdfContent === undefined || (i.pdfContent !== undefined && i.pdfContent !== undefined))));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.selectAll();
  }

  async onPrint(element: LogisticDocument) {
    if (element.pdfContent !== undefined && element.documentStatus === DocumentContentStatus.Ok && element.pdfContent.content !== undefined) {
      try {
        const printerName = 
        await firstValueFrom(this.documentPrintService.getPrinterNameFromDocumentType(element.type));
        await this.documentPrintService.printPdf(element.pdfContent.content, printerName);
        this.textNotificationService.text('Printed');
      } catch (error) {
        this.showNotificationError(error as Error);
      }
    } else {
      if (element.documentStatus === DocumentContentStatus.NoFileContent) {
        this.textNotificationService.text('Empty document content');
      }
      if (element.documentStatus === DocumentContentStatus.PdfError) {
        this.textNotificationService.text('Pdf error');
      }
    }
  }

  private showGeneralNotificationError(error: Error, message: string | undefined = undefined) {
    const text = message ?? error.message;
    this.textNotificationService.error(text);
    this.errorHistoryService.registerError(error.name, text, error);
  }

  private showNotificationError(error: Error) {
    let text: string | undefined = undefined;
    if (error instanceof InternalError) {

      switch (error.name) {
        case 'PRINTPDF_0001':
          text = 'Failed get documents';
          break;
        case 'PRINTPDF_0002':
          text = 'Failed print documents';
          break;
        case 'CLOSEBOX_1':
          text = 'Box not selected';
          break;
        case 'CLOSEBOX_2':
          text = 'Call to backend failed in close box';
          break;
        default:
          text = 'Failed general print documents';
          break;
      }
    }
    this.showGeneralNotificationError(error, text);
  }

  onMessage(element: LogisticDocument) {
    let message = '';
    switch (element.documentStatus) {
      case DocumentContentStatus.NoFileContent:
        message = 'Empty document content';
        break;
      case DocumentContentStatus.Ok:
        message = 'Document ready to print';
        break;
      case DocumentContentStatus.PdfError:
        message = 'Pdf error';
        break;
      default:
        break;
    }
    this.textNotificationService.text(message);
  }

}
