import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AuthorizationTokenService } from './authorization-token.service';

@Injectable()
export class MockAuthorizationTokenService implements AuthorizationTokenService {

    private autenticateSubject = new BehaviorSubject<boolean>(false);
    private _tokenValue : string = "";
    public get tokenValue() : string {
        return this._tokenValue;
    }
    public set tokenValue(v : string) {
        this._tokenValue = v;
        this.autenticateSubject.next(v !== undefined && v.length > 0);
    }
    
    constructor() {
        this.tokenValue = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IjNQYUs0RWZ5Qk5RdTNDdGpZc2EzWW1oUTVFMCIsImtpZCI6IjNQYUs0RWZ5Qk5RdTNDdGpZc2EzWW1oUTVFMCJ9.eyJhdWQiOiJodHRwczovL2FwaS5idXNpbmVzc2NlbnRyYWwuZHluYW1pY3MuY29tIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvZWY0MmQzMTktNDdjOS00ZjZiLWIzN2YtZTc5NWQyYmQxYzVlLyIsImlhdCI6MTczMTI1MzUzMSwibmJmIjoxNzMxMjUzNTMxLCJleHAiOjE3MzEyNTc0MzEsImFpbyI6ImsyQmdZSWl4V2J6VllJOEVwMTh1bndhWDhnTWpBQT09IiwiYXBwaWQiOiJkMWRjYzdmMS0xMDI3LTQxNDktOTBkYS00YzE4NGFiYTk2N2YiLCJhcHBpZGFjciI6IjEiLCJpZHAiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9lZjQyZDMxOS00N2M5LTRmNmItYjM3Zi1lNzk1ZDJiZDFjNWUvIiwiaWR0eXAiOiJhcHAiLCJvaWQiOiIyZjdiYTZlZC1jZGY0LTRkZjQtYTJiZC0wOWM3YmY0MzQ4ZWYiLCJyaCI6IjEuQVU4QUdkTkM3OGxIYTAtemYtZVYwcjBjWGozdmJabHNzMU5CaGdlbV9Ud0J1SjlQQUFCUEFBLiIsInJvbGVzIjpbIkF1dG9tYXRpb24uUmVhZFdyaXRlLkFsbCIsImFwcF9hY2Nlc3MiLCJBUEkuUmVhZFdyaXRlLkFsbCIsIkFkbWluQ2VudGVyLlJlYWRXcml0ZS5BbGwiXSwic3ViIjoiMmY3YmE2ZWQtY2RmNC00ZGY0LWEyYmQtMDljN2JmNDM0OGVmIiwidGlkIjoiZWY0MmQzMTktNDdjOS00ZjZiLWIzN2YtZTc5NWQyYmQxYzVlIiwidXRpIjoiLWtJNFk1cFo4RUtZcXZ6WU12RHBBQSIsInZlciI6IjEuMCIsInhtc19pZHJlbCI6IjIyIDcifQ.G_dJLHW6dYST0Z8jqXefmzYHYZX67G_KGsNkSdnIwPsKlf001APIfoHYc3T97ozkeG9cRIRKmiXTs0DV8gguuMjSXOlOCb33k8TwvP151XCvzWUyk0xpK1l5-CBWGJS-2Qz86jUsIlETYEUIT6YoWMSuSKKOYObH7HmvCot9McddQpwyTmQ5zQ76nSVe8y016L1QmVDEC1DBudHvtG_tKJLV0zhpoytfh4-5rjYFU2aEreY1FLCCy--JfydHgednwZpJntUMpamue3zemq5rhZ2GfEAkdaoy4UuHmowSuy6_wOIzWzqaEpm0nqXAEcVXTRV8WcG_95isQv4Y3ZyxpA';
     }
    public getExternalToken(): Observable<string> {
        throw new Error('Method not implemented.');
    }
    public isAutenticated(): Observable<boolean> {
        return this.autenticateSubject.asObservable();;
    }

    public getToken(): Observable<string> {
        return of(this._tokenValue);
    }
}