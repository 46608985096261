import { PrintService } from "src/app/print/services/print.service";
import { DocumentPrintService } from "./document-print.service";
import { DocumentDownloadService } from "./document-download.service";
import { from, map, Observable } from "rxjs";
import { DocumentTypePrinter } from "../model/document-type-printer";
import { ElectronService } from "src/app/electron/services/electron.service";
import { Injectable } from "@angular/core";

@Injectable()
export class DocumentPrintElectronService extends DocumentPrintService {
    constructor(printService: PrintService,
        documentDownloadService: DocumentDownloadService,
        private electronService: ElectronService) {
        super(printService, documentDownloadService);
        this.electronService = electronService;
    }

    public getPrinterList(): Observable<DocumentTypePrinter[]> {
        return this.electronService.invoke<DocumentTypePrinter[]>('get-printer-list');
    }

    public getPrinterNameFromDocumentType(documentType: string): Observable<string> {
        return this.getPrinterList().pipe(map(printerList => {
            const printer = printerList.find(printer => printer.documentType === documentType);
            return printer ? printer.printerName : '';
        }));
    }
}