import { Observable } from "rxjs";
import { ElectronDetectionService } from "./electron-detection.service";
import { ElectronWindow, IElectron } from "../model/electron-window";
import { Injectable } from "@angular/core";

@Injectable()
export class ElectronService {

    constructor(private electronDetectionService: ElectronDetectionService) { }

    private _electron: IElectron | null = null;
    public get electron(): IElectron | null {
        if (!this._electron) {
            const electronWindow = window as unknown as ElectronWindow;
            if (this.isElectronApp) {
                this._electron = electronWindow.electronAPI;
                return this._electron;
            }
            return null;
        }
        return this._electron;
    }

    get isElectronApp(): boolean {
        return this.electronDetectionService.isElectron();
    }

    public invoke<T>(channel: string, ...args: any[]): Observable<T> {
        
        return new Observable<T>(subscriber => {
            try {
                if (this.electron) {
                    this.electron.ipcRenderer.invoke(channel, ...args)
                        .then((result: T) => {
                            subscriber.next(result);
                            subscriber.complete();
                        })
                        .catch((error: any) => {
                            subscriber.error(error);
                        });
                } else {
                    subscriber.error(new Error('Electron not found'));
                }
            } catch (error) {
                subscriber.error(error);
            }
        });
    }

}