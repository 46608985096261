import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackMessageComponent } from './snack-message/snack-message.component';
import { MatIconModule } from '@angular/material/icon';
import { PackingStateComponent } from './packing-state/packing-state.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { MatButtonModule } from '@angular/material/button';
import { ScrollIntoViewDirective } from './scroll-into-view.directive';
import { AutofocusDirective } from './autofocus/autofocus.directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TextNotificationService } from './services/text-notification.service';
import { NullWithDefaultPipe } from './null-with-default.pipe';
import { KpiCardComponent } from './kpi-card/kpi-card.component';
import { MatCardModule } from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { CountUpModule } from "ngx-countup";
import { UniqueCountPipe } from './unique-count.pipe';
import { DisabledOnWorkingDirective } from './disabled-on-working/disabled-on-working.directive';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ErrorHistoryViewDialogComponent } from './error-history-view-dialog/error-history-view-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';



@NgModule({
  declarations: [
    SnackMessageComponent,
    PackingStateComponent,
    IconButtonComponent,
    ScrollIntoViewDirective,
    AutofocusDirective,
    NullWithDefaultPipe,
    KpiCardComponent,
    UniqueCountPipe,
    DisabledOnWorkingDirective,
    ConfirmDialogComponent,
    ErrorHistoryViewDialogComponent,
    ScrollTopComponent,
    ErrorDialogComponent
  ],
  exports: [
    PackingStateComponent,
    IconButtonComponent,
    ScrollIntoViewDirective,
    AutofocusDirective,
    NullWithDefaultPipe,
    KpiCardComponent,
    UniqueCountPipe,
    DisabledOnWorkingDirective,
    ScrollTopComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatCardModule,
    MatProgressBarModule,
    CountUpModule,
    MatDialogModule,
    MatTableModule
  ],
  providers: [
    TextNotificationService
  ]
})
export class CommonAppModule { }
