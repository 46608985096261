import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintService } from './services/print.service';
import { PrintBrowserService } from './services/print-browser.service';
import { PrintElectronService } from './services/print-electron.service';
import { electronFactoryService } from '../electron/services/electron-factory';
import { ElectronDetectionService } from '../electron/services/electron-detection.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class PrintModule {
  static forRoot(): ModuleWithProviders<PrintModule> {
    return {
      ngModule: PrintModule,
      providers: [
        PrintBrowserService,
        PrintElectronService,
        { provide: PrintService, 
          useFactory: electronFactoryService,
          deps: [ElectronDetectionService, PrintBrowserService, PrintElectronService]}
      ]
    };
  }
}
