import { PDFDocument, PDFPage } from 'pdf-lib';
import { Observable, forkJoin, from, map, switchMap } from 'rxjs';
import { PrintStatus } from '../model/print-status';


export abstract class PrintService {

  constructor() { }

  public abstract printFile(data: Uint8Array, printerName: string): Observable<PrintStatus>;

  public convertListToPdf(data: ArrayBuffer[]): Observable<PDFDocument>[] {
    return data.map(this.convertToPdf);
  }

  public convertToPdf(data: ArrayBuffer): Observable<PDFDocument> {
    return from(PDFDocument.load(data));
  }

  public preparePrintPdf(pdfDocumentPages: PDFDocument[]): Observable<PDFDocument> {

    return from(PDFDocument.create()).pipe(
      switchMap(documentData => {
        const pageList: Observable<PDFPage[]>[] = [];
        for (const pdf of pdfDocumentPages) {
          pageList.push(from(documentData.copyPages(pdf, pdf.getPageIndices())));
        }
        return forkJoin(pageList).pipe(
          map(dataPage => {
            dataPage.forEach((pageList) => pageList.forEach((page) => documentData.addPage(page)));
            return documentData;
          }))
      }));
  }

  public printPdf(pdfDocument: PDFDocument, printerName: string): Observable<PrintStatus> {
    /* Array of pdf urls */
    // let pdfsToMerge = ['http://127.0.0.1:5500/src/dummy.pdf'];
    return from(pdfDocument.save()).pipe(switchMap(data => this.printFile(data, printerName)));
  }

}
