import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { OrderKey } from 'src/app/order/model/order-key';
import { OrderService } from 'src/app/order/services/order.service';
import { Box } from '../model/box';
import { BoxContentItem } from '../model/box-content-item';
import { BoxContentItemRepository } from '../services/state/box-content-item.repository';
import { PackageOrderApiService } from './package-order-api.service';
import { PackageOrderService } from './package-order.service';
import { BoxRepository } from './state/box.repository';
import { ArticleIncidentType } from 'src/app/incident/model/article-incident-type';
import { BoxPacked, OperationBoxShiping } from '../model/box-packed';
import { InternalError } from 'src/app/common-app/model/internal-error';
import { IncidentService } from 'src/app/incident/services/incident.service';
import { OrderState } from 'src/app/order/model/order-state';
import { OrderIncidence } from 'src/app/order/model/order-incidence';

@Injectable({
  providedIn: 'root'
})
export class PackageOrderCacheService extends PackageOrderService {


  constructor(
    private boxContentItemRepository: BoxContentItemRepository,
    private boxRespository: BoxRepository,
    private packageOrderApiService: PackageOrderApiService,
    private orderService: OrderService,
    private incidentService: IncidentService
  ) {
    super();
  }

  public setActiveBoxType(id: string, boxModelId: string, closed: boolean): Observable<Box> {
    const result = new Box();
    result._id = id;
    result.boxModelId = boxModelId;
    result.closed = closed;
    this.boxRespository.addBox(result, true);
    return of(result);
  }

  public updateBoxType(boxModelId: string, slotId: string): Observable<Box> {
    return this.getActiveBoxOrNew(boxModelId, slotId);
  }

  public getOrderContent(): Observable<BoxContentItem[]> {
    return this.boxContentItemRepository.boxContentItem$;
  }

  public getBoxModel(): string | undefined {
    return this.boxRespository.getActiveBoxModelId();
  }

  public resetBox(): void {
    this.boxRespository.resetBox();
    this.boxContentItemRepository.deleteBoxContentItemAll();
    this.boxContentItemRepository.resetActive();
  }

  public labelBox(boxId: string): Observable<OperationBoxShiping[]> {
    return this.packageOrderApiService.labelBox(boxId).pipe(
      take(1),
      map(data => {
        if (data !== null) {
          return data;
        } else {
          throw new Error("Data collected on label not correct");
        }
      }),
      catchError(error =>
        throwError(() => new InternalError('LABELBOX_1', 'Call to backend for label box failed', error, undefined))
      )
    );
  }

  public closeBox(orderId: OrderKey, weight: number, lastBox: boolean): Observable<BoxPacked> {
    const box = this.boxRespository.getActiveBox();
    if (box.length > 0) {
      const boxId = box[0]._id;
      const order = this.orderService.getOrder(orderId);
      if (order === undefined) {
        return of({ boxId, shipingList: [] } as BoxPacked);
      } else {
        return this.packageOrderApiService.closeBox(order.pickId, order.id, order.slotId as string, boxId, weight, lastBox).pipe(
          take(1),
          tap(_data => {
            this.orderService.closeBox(orderId, boxId);
            this.boxRespository.closeBox(boxId);
            this.boxContentItemRepository.closeBox(boxId);
            this.boxContentItemRepository.resetActive();
          }),
          catchError(error =>
            throwError(() => new InternalError('CLOSEBOX_2', 'Call to backend for close box failed', error, undefined))),
        )
      }
    }
    const error = new InternalError('CLOSEBOX_1', 'No hay caja seleccionada', undefined, undefined);
    return throwError(() => error);
  }

  public changeBoxType(boxId: string, boxType: string): Observable<Box | undefined> {
    return this.packageOrderApiService.changeBoxType(boxId, boxType).pipe(
      switchMap(data => {
        if (data !== null) {
          this.boxRespository.updateBox(boxId, { boxModelId: boxType });
          this.boxContentItemRepository.upsertBoxModelType(boxId, boxType);
          this.orderService.updateBoxModelType(boxId, boxType);
          return of(this.boxRespository.getEntity(boxId));
        } else {
          return throwError(() => new Error("Box data collected on change not correct"));
        }
      }));
  }

  private getActiveBoxOrNew(boxType: string, slotId: string): Observable<Box> {
    const boxList = this.boxRespository.getActiveBox();
    if (boxList.length > 0) {
      return of(boxList[0]);
    } else {
      return this.packageOrderApiService.createBox(boxType, slotId).pipe(
        map(data => {
          let result: Box | null = null;
          if (data !== null) {
            if (data.Datos !== null && data.Datos.length > 0) {
              result = new Box();
              result._id = data.Datos[0]['Container Id'] as string;
              result.boxModelId = boxType;
              this.boxRespository.addBox(result, true);
              return result;
            }
          }
          if (result === null) {
            throw new Error("Box data collected on create not correct")
          } else {
            return result;
          }
        })
      )
    }
  }

  public getActiveBox(): Box | undefined {
    const boxList = this.boxRespository.getActiveBox();
    if (boxList.length > 0) {
      return boxList[0];
    } else {
      return undefined;
    }
  }

  public restartBoxOrder(orderId: OrderKey): Observable<void> {
    return this.orderService.selectOrder(orderId, false).pipe(
      switchMap(order => order !== undefined ? 
        this.packageOrderApiService.restartBoxOrder(order.pickId, order.id, order.slotId as string) : throwError(() => new Error('Order not found'))),
      map(_data => {})
    );
  }

  public pullItem(orderId: OrderKey, boxType: string, sku: string, barCode: string[], count: number): Observable<string> {
    if (orderId.slotId !== undefined) {
      return this.getActiveBoxOrNew(boxType, orderId.slotId).pipe(
        take(1),
        switchMap(box => this.orderService.selectOrder(orderId, false).pipe(
          take(1),
          switchMap(order =>
            order !== undefined ? this.packageOrderApiService.pullItem(order.pickId, order.id, order.slotId as string, box._id, sku, count).pipe(
              take(1),
              map(data => {
                this.boxContentItemRepository.upsertBoxContent(orderId.id as string, sku, count, box);
                if (data !== null) {
                  this.orderService.updateStatus(orderId, data.orderStatus);
                }
                return box._id;
              })
            ) : of(box._id)
          )
        ))
      );
    } else {
      return throwError(() => new Error('Ubicacion no propocionada'));
    }
  }

  public pullIncident(orderId: OrderKey, sku: string, count: number, incidentValue: ArticleIncidentType): Observable<string | null> {
    return this.incidentService.declareIncident(orderId, sku, count, incidentValue)
      .pipe(
        tap(data => this.orderService.updateIncident(orderId, data, incidentValue, sku, count)),
        map(data => sku));
  }

  public orderPullIncident(orderId: OrderKey, incidentValue: OrderIncidence): Observable<OrderState> {
    return this.incidentService.declareOrderIncident(orderId, incidentValue)
      .pipe(
        tap(data => this.orderService.updateOrderIncident(orderId, data, incidentValue)));
  }
}
