import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import * as printJS from "print-js";
import { InternalError } from "src/app/common-app/model/internal-error";
import { PrintStatus } from "../model/print-status";
import { PrintService } from './print.service';

@Injectable()
export class PrintBrowserService extends PrintService {

  printFile(data: Uint8Array, printerName: string): Observable<PrintStatus> {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const event$ = new Subject<PrintStatus>();
    printJS({
      printable: url,
      type: 'pdf',
      onLoadingEnd: () => event$.next(PrintStatus.loadingEnd),
      onLoadingStart: () => event$.next(PrintStatus.loadinStart),
      onPrintDialogClose: () => {
        event$.next(PrintStatus.printDialogClose);
        event$.complete();
      },
      onError: (error, _request) => event$.error(new InternalError('PRINTPDF_0002', 'Fallo al imprimir el fichero', error, undefined))
    })
    return event$.asObservable();
  }
}