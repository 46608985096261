import { Observable, of } from "rxjs";
import { PrintService } from "src/app/print/services/print.service";
import { DocumentDownloadService } from "./document-download.service";
import { DocumentPrintService } from "./document-print.service";
import { DocumentTypePrinter } from "../model/document-type-printer";
import { Injectable } from "@angular/core";

@Injectable()
export class DocumentPrintBrowserService extends DocumentPrintService {

    constructor(
        printService: PrintService,
        documentDownloadService: DocumentDownloadService) {
        super(printService, documentDownloadService);
    }
    getPrinterNameFromDocumentType(documentType: string): Observable<string> {
        return of('default');
    }

    public override getPrinterList(): Observable<DocumentTypePrinter[]> {
        return of([]);
    }
}