import { Injectable } from '@angular/core';
import { Observable, forkJoin, map, mergeMap, take } from 'rxjs';
import { BasicHttpClientService } from 'src/app/api/services/basic-http-client.service';
import { OperationResponse } from 'src/app/order/model/api/operation-response';
import { QueryOrderPackagingContainerList } from 'src/app/order/model/api/order-packaging-container';
import { CreateBoxRequest } from '../model/api/create-box-request';
import { CreateBoxResponse } from '../model/api/create-box-response';
import { LeerProductoContenedorEmpaquetadoRequest } from '../model/api/leer-producto-contenedor-empaquetado-request';
import { LeerProductoContenedorEmpaquetadoResponse } from '../model/api/leer-producto-contenedor-empaquetado-response';
import { CloseBoxRequest } from '../model/api/close-box-request';
import { CloseBoxResponse, DocumentShippingResponse } from '../model/api/close-box-response';
import { UserService } from 'src/app/security/services/user-service';
import { BoxDocument, BoxPacked, BoxShiping, OperationBoxShiping } from '../model/box-packed';
import { OrderApiHelper } from 'src/app/order/services/order-api-helper';
import { LeerProductoContenedorEmpaquetado } from '../model/leer-producto-contenedor-empaquetado';
import { OrderState } from 'src/app/order/model/order-state';
import { ComposeUrlService } from 'src/app/api/services/compose-url.service';
import { ProductivityService } from 'src/app/productivity/services/productivity.service';
import { RestartOrderBoxRequest } from '../model/api/restart-order-box-request';
import { ChangeBoxTypeRequest } from '../model/api/change-box-type-request';
import { ChangeBoxTypeResponse } from '../model/api/change-box-type-response';
import { RestartOrderBoxResponse } from '../model/api/restart-order-box-response';
import { LabelBoxRequest } from '../model/api/label-box-request';
import { LabelBoxDatoResponse, LabelBoxResponse } from '../model/api/label-box-response';

@Injectable({
  providedIn: 'root'
})
export class PackageOrderApiService {

  constructor(
    private httpClient: BasicHttpClientService,
    private composeUrlService: ComposeUrlService,
    private userService: UserService,
    private productivityService: ProductivityService) { }

  private convertOperationBoxShiping(data: LabelBoxDatoResponse[]): OperationBoxShiping[] {
    return data.map<OperationBoxShiping>(i => {
      return {
        created: i.Created,
        documents: this.convertBoxShiping(i.Documents)
      }
    });
  }

  private convertBoxShiping(data: DocumentShippingResponse[]): BoxDocument[] {
    return data.map<BoxDocument>((d) => { return { 
      url: d.Value,
      type: d.Type
     } })
  }
  private convertCloseBox(data: CloseBoxResponse, boxId: string): BoxPacked {
    return {
      shipingList: data.Datos.map<BoxShiping>(i => {
        return {
          boxId,
          originalStatus: i['Order Status'],
          status: OrderApiHelper.convertStatus(i['Order Status']),
          documents: this.convertBoxShiping(i.Documents)
        }
      })
    } as BoxPacked
  }

  public closeBox(pickId: string, orderId: string, slotId: string, boxId: string, weight: number, ultimaCaja: boolean): Observable<BoxPacked> {
    return forkJoin([
      this.userService.selectUserName().pipe(take(1)),
      this.productivityService.selectProductivity().pipe(take(1))]).pipe(
      mergeMap(data => {
        const query: CloseBoxRequest = {
          bUltimaCaja: ultimaCaja,
          cContenedor: boxId,
          cDocumento: pickId,
          cPedido: orderId,
          cUbicacion: slotId,
          cUsuarioPDA: data[0] as string,
          iId: data[1] as number,
          dPeso: weight
        };
        return this.httpClient
          .post<CloseBoxRequest, CloseBoxResponse>(this.composeUrlService.composeUrl(`CosmosApi_CerrarContenedorEmpaquetado`), query).pipe(map(data => this.convertCloseBox(data, boxId)))
      })
    );
  }

  public labelBox(boxId: string): Observable<OperationBoxShiping[]> {
    return this.userService.selectUserName().pipe(
      take(1),
      mergeMap(data => {
        const query: LabelBoxRequest = {
          cContainer: boxId,
          cUsuarioPDA: data as string
        };
        return this.httpClient
          .post<LabelBoxRequest, LabelBoxResponse>(this.composeUrlService.composeUrl(`CosmosApi_ObtenerEtiquetaContenedor`), query)
          .pipe(map(data => this.convertOperationBoxShiping(data.Datos)))
      })
    );
  }

  public restartBoxOrder(pickId: string, orderId: string, slotId: string): Observable<RestartOrderBoxResponse> {
    return  forkJoin([
      this.userService.selectUserName().pipe(take(1)),
      this.productivityService.selectProductivity().pipe(take(1))]).pipe(
      mergeMap(data => {
        const query: RestartOrderBoxRequest = {
          iId: data[1] as number,
          cDocumento: pickId,
          cUsuarioPDA: data[0] as string,
          cPedido: orderId,
          cUbicacion: slotId
        }
        return this.httpClient
          .post<RestartOrderBoxRequest, RestartOrderBoxResponse>(this.composeUrlService.composeUrl(`CosmosApi_ReiniciarCajasPedido`), query);
      })
    );
  }

  public createBox(boxType: string, slotId: string): Observable<CreateBoxResponse | null> {
    return  forkJoin([
      this.userService.selectUserName().pipe(take(1)),
      this.productivityService.selectProductivity().pipe(take(1))]).pipe(
      mergeMap(data => {
        const query: CreateBoxRequest = {
          iId: data[1] as number,
          cTipo: boxType,
          cUsuarioPDA: data[0] as string,
          cUbicacion: slotId
        }
        return this.httpClient
          .post<CreateBoxRequest, CreateBoxResponse>(this.composeUrlService.composeUrl(`CosmosApi_CrearCajaEmpaquetado`), query);
      })
    );
  }

  public changeBoxType(boxId: string, boxType: string): Observable<ChangeBoxTypeResponse | null> {
    return  forkJoin([
      this.userService.selectUserName().pipe(take(1)),
      this.productivityService.selectProductivity().pipe(take(1))]).pipe(
      mergeMap(data => {
        const query: ChangeBoxTypeRequest = {
          iId: data[1] as number,
          cTipo: boxType,
          cUsuarioPDA: data[0] as string,
          cContenedor: boxId
        }
        return this.httpClient
          .post<ChangeBoxTypeRequest, ChangeBoxTypeResponse>(this.composeUrlService.composeUrl(`CosmosApi_CambiarTipoCajaEmpaquetado`), query);
      })
    );
  }

  public pullItem(pickId: string, orderId: string, slotId: string, boxId: string, sku: string, count: number): Observable<LeerProductoContenedorEmpaquetado | null> {
    return forkJoin([
      this.userService.selectUserName().pipe(take(1)),
      this.productivityService.selectProductivity().pipe(take(1))]).pipe(
      mergeMap(data => {
        const query: LeerProductoContenedorEmpaquetadoRequest = {
          cContenedor: boxId,
          cDocumento: pickId,
          cPedido: orderId,
          cProducto: sku,
          cUbicacion: slotId,
          cUsuarioPDA: data[0] as string,
          dCantidad: count,
          iId: data[1] as number
        };
        return this.httpClient
          .post<LeerProductoContenedorEmpaquetadoRequest, LeerProductoContenedorEmpaquetadoResponse>(this.composeUrlService.composeUrl(`CosmosApi_LeerProductoContenedorEmpaquetado`), query)
          .pipe(map(data => {
            return {
              orderStatus: data.Datos.length === 0 ? OrderState.Unknow : OrderApiHelper.convertStatus(data.Datos[0]['Order Status'])
            } as LeerProductoContenedorEmpaquetado
          }))
      })
    );
  }

  public closePicking(pickId: string): Observable<OperationResponse | null> {
    return forkJoin([
      this.userService.selectUserName().pipe(take(1)),
      this.productivityService.selectProductivity().pipe(take(1))])
     .pipe(
      mergeMap(data => {
        const query: QueryOrderPackagingContainerList = {
          "iId": data[1] as number,
          "eActividad": "7",
          "eOperacion": "6",
          "eAccion": "4",
          "cUsuarioPDA": data[0] as string,
          "cPedido": "",
          "eEstado": "0",
          "eTipoDoc": "2",
          "cDoc": "EMPAQUETADO",
          "cDocumento": pickId,
          "cContenedor": "",
          "cUbicacion": "",
          "cTipo": "0",
          "cPadre": "",
          "bSSCC": false,
          "cProducto": "",
          "dCantidad": 0,
          "bTotal": false
        }
        return this.httpClient
          .post<QueryOrderPackagingContainerList, OperationResponse>(this.composeUrlService.composeUrl(`CosmosApi_EjecutarAccionProductividad`), query);
      })
    );
  }
}
