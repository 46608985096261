import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurityModule } from '../security/security.module';
import { ApiModule } from '../api/api.module';
import { ContainerModule } from '../container/container.module';
import { MasterModule } from '../master/master.module';
import { OrderModule } from '../order/order.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { EnviromentModule } from '../enviroment/enviroment.module';
import { SecurityPassagePasskeyModule } from '../security-passage-passkey/security-passage-passkey.module';
import { NavigationModule } from '../navigation/navigation.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../security/interceptor/auth.interceptor';
import { PackageModule } from '../package/package.module';
import { DeliveryActionsModule } from '../delivery-actions/delivery-actions.module';
import { PrintModule } from '../print/print.module';
import { ElectronModule } from '../electron/electron.module';
import { DocumentModule } from '../document/document.module';
import { ConfigModule } from '../config/config.module';

// export function fetchBuildDetails(upToDateBuildService: UpToDateBuildService) {
//   return () => {
//     upToDateBuildService.buildIsUpToDate.subscribe(isUpToDate => {
//       if (!isUpToDate) {
//         console.log('Build is not up to date');
//       }
//     });
//   }
// }

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ElectronModule.forRoot(),
    PrintModule.forRoot(),
    SecurityPassagePasskeyModule.forRoot(),
    SecurityModule.forRoot(),
    ApiModule.forRoot(),
    DocumentModule.forRoot(),
    OrderModule.forRoot(),
    EnviromentModule.forRoot(),
    ContainerModule,
    MasterModule,
    NgxSpinnerModule.forRoot({ type: 'square-jelly-box' }),
    NavigationModule,
    PackageModule.forRoot(),
    DeliveryActionsModule.forRoot(),
    ConfigModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    // { provide: APP_INITIALIZER, useFactory: fetchBuildDetails, deps: [UpToDateBuildService], multi: true },
  ],
})
export class CoreModule { }
