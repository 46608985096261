import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainPageComponent } from '../layout/main-page/main-page.component';
import { EditConfigPortalComponent } from './edit-config-portal/edit-config-portal.component';


const routes: Routes = [
  {
    path: '',
    component: MainPageComponent,
    children: [
      { path: 'portal', component: EditConfigPortalComponent }
    ]
  }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConfigRoutingModule { }